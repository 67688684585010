import React, {Component} from 'react';

class AppFooter extends Component {
    render() {
        return (
            <footer>
                <p>Copyright &copy; {(new Date().getFullYear())} El amor se contagia.</p>
            </footer>
        );
    }
}

export default AppFooter;
