import React, {Component} from 'react';
import AppFrame from "../components/AppFrame";
import capsula1 from "../capsulas/capsula_1.mp4";
import capsula2 from "../capsulas/capsula_2.mp4";
import capsula3 from "../capsulas/capsula_3.mp4";
import capsula4 from "../capsulas/capsula_4.mp4";
import capsula5 from "../capsulas/capsula_5.mp4";
import capsula6 from "../capsulas/capsula_6.mp4";
import capsula7 from "../capsulas/capsula_7.mp4";
import capsula8 from "../capsulas/capsula_8.mp4";
import capsula9 from "../capsulas/capsula_9.mp4";

class VideosContainer extends Component {
   renderBody() {
      return (
         <div className="videos-container">
            <div className="title_section">
               <h2>Nuestras Cápsulas</h2>
            </div>
            <div className="capsules-container">
               <div className="capsule">
                  <video controls>
                     <source src={capsula1} type="video/mp4" />
                  </video>
               </div>
               <div className="capsule">
                  <video controls>
                     <source src={capsula2} type="video/mp4" />
                  </video>
               </div>
               <div className="capsule">
                  <video controls>
                     <source src={capsula3} type="video/mp4" />
                  </video>
               </div>
               <div className="capsule">
                  <video controls>
                     <source src={capsula4} type="video/mp4" />
                  </video>
               </div>
               <div className="capsule">
                  <video controls>
                     <source src={capsula5} type="video/mp4" />
                  </video>
               </div>
               <div className="capsule">
                  <video controls>
                     <source src={capsula6} type="video/mp4" />
                  </video>
               </div>
               <div className="capsule">
                  <video controls>
                     <source src={capsula7} type="video/mp4" />
                  </video>
               </div>
               <div className="capsule">
                  <video controls>
                     <source src={capsula8} type="video/mp4" />
                  </video>
               </div>
               <div className="capsule">
                  <video controls>
                     <source src={capsula9} type="video/mp4" />
                  </video>
               </div>
            </div>
         </div>
      );
   }

   render() {
      return (
         <AppFrame body={this.renderBody()}/>
      );
   }
}

export default VideosContainer;