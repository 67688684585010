import React from 'react';
// noinspection ES6CheckImport
import {BrowserRouter as Router, Route} from 'react-router-dom';
import HomeContainer from "./sitio-web/containers/HomeContainer";
import FoundationContainer from "./sitio-web/containers/FoundationContainer";
import FamiliesContainer from "./sitio-web/containers/FamiliesContainer";
import VideosContainer from "./sitio-web/containers/VideosContainer";

function App() {
  return (
      <Router>
        <Route exact path="/" component={HomeContainer}/>
        <Route exact path="/fundacion-sonrie-a-la-vida" component={FoundationContainer}/>
        <Route exact path="/el-amor-se-contagia" component={FamiliesContainer}/>
        <Route exact path="/capsulas" component={VideosContainer}/>
      </Router>
  );
}

export default App;
