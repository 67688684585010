import React, {Component} from 'react';
// noinspection ES6CheckImport
import {withRouter, NavLink} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/estilos.css';
import {setActiveMenu} from "../../actions/menuActions";
import { connect } from 'react-redux';
import logo from "../img/logo.png";

class AppHeader extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    handleChange = (event) => {
        let menu = event.target.value;
        this.props.setActiveMenu(menu);
        this.props.history.push(menu);
    }

    render() {
        return (
            <div className="header">
                <div className="main_menu">
                    <div className="logo_container">
                        <NavLink exact to="/" activeClassName="active" className="logo">
                            <img src={logo} alt="El amor se contagia" />
                        </NavLink>
                    </div>
                    <div className="menu list_menu">
                        <NavLink exact to="/" activeClassName="active">Inicio</NavLink>
                        <NavLink exact to="/fundacion-sonrie-a-la-vida" activeClassName="active">Fundación Sonríe a la Vida A.C.</NavLink>
                        <HashLink to="/fundacion-sonrie-a-la-vida#metodologia">Give Love</HashLink>
                        <NavLink exact to="/el-amor-se-contagia" activeClassName="active">El amor se contagia</NavLink>
                        <HashLink to="/fundacion-sonrie-a-la-vida#metodologia">Active Love</HashLink>
                        <NavLink exact to="/capsulas" activeClassName="active">Cápsulas</NavLink>
                    </div>
                </div>
                <div className="menu-mobile">
                    <div className="contenedor-menu">
                        <div className="logo_container">
                            <NavLink exact to="/" activeClassName="active" className="logo">
                                <img src={logo} alt="El amor se contagia" />
                            </NavLink>
                        </div>
                        <div className="select">
                            <select id="menu-select" onChange={this.handleChange} value={this.props.menu}>
                                <option value="">Menú</option>
                                <option value="/">Inicio</option>
                                <option value="/fundacion-sonrie-a-la-vida">Fundación Sonríe a la Vida A.C.</option>
                                <option value="/fundacion-sonrie-a-la-vida#metodologia">Give Love</option>
                                <option value="/el-amor-se-contagia">El amor se contagia</option>
                                <option value="/fundacion-sonrie-a-la-vida#metodologia">Active Love</option>
                                <option value="/capsulas">Cápsulas</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state)=>{
    return {
        menu: state.menu.activeMenu
    }
}

const mapDispatchToProps= (dispatch)=>{
    return {
        setActiveMenu: (menu) => {
            dispatch(setActiveMenu(menu))
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(AppHeader));
