import React, {Component} from 'react';
import AppFrame from "../components/AppFrame";
import vision from "../img/vision.jpg";
import mision from "../img/mision.jpg";
import logo from "../img/logo_fundacion.jpg";

class FoundationContainer extends Component {
    renderBody() {
        return (
            <div className="foundation-container">
                <div className="section">
                    <div className="content">
                        <div className="column">
                            <p>En síntesis este proyecto tiene como finalidad el ofrecer un apoyo a madres y familias en situación de pobreza. Estas familias objetivo además de que están pasando por una situación de pobreza también se están enfrentando una adversidad en la que alguno de sus hijos tiene una enfermedad de atención especial (leucemia, insuficiencia renal, autismo, paralísis cerebral o alguna discapacidad).</p>
                            <p>Este apoyo consiste en entregar de manera mensual una "Macro despensa" la cual incluye una serie de productos pensados para alimentar a la familia en cuestión durante todo un mes.</p>
                            <p>Aunado a esta entrega de Macro despensas, en la fecha de cumpleaños del niño enfermo se le llevará ya sea a su domicilio, hospital o centro de rehabilitación un kit cumpleañero que consiste en un regalo para él, un pastel con velas, una piñata y unos bolos para que pueda tener una celebración con sus seres queridos.</p>
                        </div>
                        <div className="column">
                            <img src={logo} alt="El amor se contagia" />
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="content">
                        <div className="column">
                            <img src={vision} alt="El amor se contagia" />
                        </div>
                        <div className="column">
                            <p>La visión que nos llevó a gestar este proyecto consiste en situarnos dentro un par de fotografías. En la primera de ellas nos encontramos en una casa con una mesa vacía, no hay que comer y vemos a una familia atormentada por lidiar con los gastos y las atenciones que requiere la enfermedad de uno de sus hijos.</p>
                            <p>En la segunda fotografía observamos en el centro de la escena a un niño o niña que además de estar en una condición social de pobreza denota una enfermedad, acompañado por sus papás esta soplando las velitas de su pastel de cumpleaños con la sonrisa más grande que pueda existir. Su madre con la mano sobre su hombro le hace saber que se esta esforzando por ser mejor para poder cuidarle y salir adelante de esta situación.</p>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title_section">
                        <h2>Nuestra Misión</h2>
                    </div>
                    <div className="content">
                        <div className="column">
                            <p>Busca que el amor se contagie y podamos ser transmisores de ese amor y  esa esperanza al ayudar en un aspecto básico como lo es la alimentación y a la vez queremos provocar que estos niños sonrían a la vida olvidándose por un momento de su enfermedad al celebrar su cumpleaños.</p>
                            <p>También es nuestra misión el enriquecer las posibilidades sociales y de vínculo de las familias favoreciendo tres aspectos esenciales.</p>
                            <ol>
                                <li>Fortalecer la autoestima.</li>
                                <li>Enriquecer la unión familiar a través de los valores.</li>
                                <li>Transmitir el amor que Cristo tiene por todos nosotros.</li>
                            </ol>
                            <p>No es solo una propuesta "asistencialista" sino que nos centramos también en el protagonismo de las familias, principalmente de las madres y su participación activa ya que tendrán que tener la disposición a formarse en esos tres aspectos esenciales de los que hemos hablado por medio de pláticas con personas capacitadas para que de esta forma podamos llevar a estas familias de lo evidente que es la macrodespensa y la celebración de cumpleaños de su hijo a lo profundo logrando un encuentro consigo mismas y con sus potenciales como madre, como mujer, como familia.</p>
                            <p>A esto le llamamos objetivos en espejo ya que al lograrse el primero (objetivo tangible y evidente), se logra el reflejo del segundo (objetivo intangible y profundo) como cuando un objeto es colocado frente a un espejo.</p>
                        </div>
                        <div className="column">
                            <img src={mision} alt="El amor se contagia" />
                        </div>
                    </div>
                </div>
                <div className="section" id="metodologia">
                    <div className="title_section">
                        <h2>Nuestra Metodología</h2>
                    </div>
                    <div className="content">
                        <div className="column-full">
                            <p>Consiste en llevar a cabo tres actividades que tienen como fin el obtener los recursos necesarios para poder apoyar a las familias objetivo buscando el involucramiento de la sociedad en cada una de estas actividades.</p>
                            <p>Las actividades son:</p>
                            <div className="activities">
                                <div className="activity">
                                    <h3>GIVE LOVE</h3>
                                    <p>Es una línea de productos con causa amparada bajo la marca "give love" y el slogan "el amor se contagia" que tiene como finalidad el destinar la utilidad generada por los productos a cumplir el objetivo de este proyecto.</p>
                                    <p>El producto inicial se basa en una colección de playeras con un distintivo que haga notar que son productos con causa.</p>
                                </div>
                                <div className="activity">
                                    <h3>EL AMOR SE CONTAGIA</h3>
                                    <p>Es una plataforma donde las personas o familias que quieran ayudar se comprometerán a cubrir el gasto de una despensa mensual.</p>
                                    <p>Estos pagos se podrán hacer a través de la página web o bien vía transferencia electrónica o depósito a la cuenta destinada para recabar los fondos.</p>
                                    <p>Una vez recibidos los fondos se llevarán las despensas a las familias adoptadas y a las familias que adoptan se les enviará la evidencia de estas entregas.</p>
                                </div>
                                <div className="activity">
                                    <h3>ACTIVE LOVE</h3>
                                    <p>Es una serie de eventos socio-deportivos los cuales tienen la finalidad de destinar los ingresos obtenidos para cumplir el objetivo de este proyecto.</p>
                                    <p>Los tipos de evento contemplados son:</p>
                                    <ol>
                                        <li>Conferencias.</li>
                                        <li>Torneos deportivos cortos (copa burpee, soccer love, rodando con amor, hooping love, clases de acondicionamiento físico)</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <AppFrame body={this.renderBody()}/>
        );
    }
}

export default FoundationContainer;