import React, {Component} from 'react';
import AppFrame from "../components/AppFrame";
import NewSponsor from "../components/families/NewSponsor";
import firebase from "../../Firebase";
import Swal from 'sweetalert2';
import {Table, Tbody, Td, Th, Thead, Tr} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import adoptar from "../img/adoptar_familias.jpeg";

class FamiliesContainer extends Component {
    constructor(props){
        super(props);
        this.db = firebase.firestore();
        this.state = {
            showForm: true,
            showRegister: false,
            showList: false,
            familiesList: []
        };
        this.tableSponsors = this.db.collection('patrocinadores');
        this.tableFamilies = this.db.collection('familias');
    }

    componentDidMount() {
        this.unsubscribe = this.tableFamilies.onSnapshot(this.getFamiliesList);
    }

    componentWillUnmount(){
        this.unsubscribe && this.unsubscribe();
    }

    getFamiliesList = (querySnapshot) => {
        const familiesList = [];
        const options = {dateStyle: "long",timeStyle: "short",timeZone: "America/Mexico_City"};
        querySnapshot.forEach((doc) => {
            const { nombre, colonia, municipio, status, patrocinador, fechaAdopcion } = doc.data();
            if(fechaAdopcion !== undefined){
                const timestamp = fechaAdopcion.seconds * 1000;
                const date = new Intl.DateTimeFormat('es-MX', options).format(timestamp);
                familiesList.push({ key: doc.id, nombre, colonia, municipio, status, patrocinador, fecha: date });
            } else {
                familiesList.push({key: doc.id, nombre, colonia, municipio, status});
            }
        });
        this.setState({ familiesList });
    }

    signInWithGoogle = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION).then(() => {
            firebase.auth().signInWithPopup(provider).then(result => {
                let name = result.user.displayName;
                let email = result.user.email;
                this.logIn(name,email);
            }).catch(() => {});
        });
    }

    logIn(name,email){
        localStorage.setItem('clientName', name);
        localStorage.setItem('clientEmail', email);
        this.db.collection('patrocinadores').where("correo", "==", email).get().then( (querySnapshot) => {
            if(querySnapshot.docs[0]){
                this.showList();
            }else{
                this.setState({showRegister: true});
            }
        });
    }

    handleSubmit = (values) => {
        const { telefono } = values;
        const email = localStorage.getItem('clientEmail');
        this.db.collection('patrocinadores').where("correo", "==", email).get().then( (querySnapshot) => {
            if (querySnapshot.docs[0]) {
                this.showList();
            } else {
                const fecha = new Date();
                const nombre = localStorage.getItem('clientName');
                const correo = localStorage.getItem('clientEmail');
                this.tableSponsors.add({ nombre, correo, telefono, fecha }).then( () => {
                    Swal.fire({
                        title: '¡Gracias!',
                        text: 'Tu cuenta ha sido creada exitosamente.',
                        icon: 'success',
                        confirmButtonText: 'Continuar'
                    }).then( () => {
                        this.showList();
                    });
                }).catch( () => {
                    Swal.fire({
                        title: '¡Lo sentimos!',
                        text: 'Hubo un problema enviando tus datos.',
                        icon: 'warning',
                        confirmButtonText: 'Continuar'
                    }).then( () => {});
                });
        }
        });
    }

    showList = () => {
        this.setState({showForm: false, showList: true});
    }

    adoptFamily = (family) => {
        const correo = localStorage.getItem('clientEmail');
        const fecha = new Date();
        this.tableFamilies.doc(family.key).update({ patrocinador: correo, fechaAdopcion: fecha, status: true }).then( () => {
            Swal.fire({
                title: '¡Operación exitosa!',
                text: 'Gracias por adoptar a la familia.',
                icon: 'success',
                confirmButtonText: 'Continuar'
            }).then(()=>{});
        }).catch( () => {
            Swal.fire({
                title: '¡Lo sentimos!',
                text: 'Hubo un problema con la base de datos.',
                icon: 'warning',
                confirmButtonText: 'Continuar'
            }).then(()=>{});
        });
    }

    renderBody() {
        return (
            <div className="families-container">
                <div id="login-container" className={this.state.showForm ? "show-container" : "hide-container"}>
                    <div className="photo-container">
                        <img src={adoptar} alt="El amor se contagia" />
                    </div>
                    <div className="form-container">
                        <div className="title_section">
                            <h2>Adoptar una Familia</h2>
                            <p>Gracias por formar parte de nuestra iniciativa, a continuación ingresa tu nombre, tu correo y un celular donde podamos contactarte por medio de Whatsapp para mandarte fotos y videos de cuando realicemos la entrega de las despensas.</p>
                        </div>
                        <div className="google login-btn" onClick={() => this.signInWithGoogle()}>
                            <FontAwesomeIcon icon={faGoogle} /> Iniciar Sesión con Google
                        </div>
                        <div className={this.state.showRegister ? "show-container" : "hide-container"}>
                            <NewSponsor onSubmit={this.handleSubmit} />
                        </div>
                    </div>
                </div>
                <div className={this.state.showList ? "show-container" : "hide-container"}>
                    <div className="title_section">
                        <h2>Familias Adoptadas</h2>
                    </div>
                    <div className="table_container">
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>Nombre del Niño</Th>
                                    <Th>Colonia</Th>
                                    <Th>Municipio</Th>
                                    <Th>Fecha de Adopción</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {this.state.familiesList.map( (family, key) => {
                                    if(family.patrocinador === localStorage.getItem('clientEmail')){
                                        return (
                                            <Tr key={key}>
                                                <Td>{family.nombre}</Td>
                                                <Td>{family.colonia}</Td>
                                                <Td>{family.municipio}</Td>
                                                <Td>{family.fecha}</Td>
                                            </Tr>
                                        )
                                    } else {
                                       return('')
                                    }
                                })}
                            </Tbody>
                        </Table>
                    </div>
                    <div className="title_section">
                        <h2>Listado de Familias</h2>
                        <p>Conoce a las familias beneficiadas por nuestra iniciativa.</p>
                    </div>
                    <div className="table_container">
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>Nombre del Niño</Th>
                                    <Th>Colonia</Th>
                                    <Th>Municipio</Th>
                                    <Th>Status</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {this.state.familiesList.map( (family, key) => {
                                    return (
                                        <Tr key={key}>
                                            <Td>{family.nombre}</Td>
                                            <Td>{family.colonia}</Td>
                                            <Td>{family.municipio}</Td>
                                            <Td>{family.status ? <span className="adopted">Adoptada</span> : <button className="btn_adopt" onClick={() => this.adoptFamily(family)}>Adoptar</button>}</Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <AppFrame body={this.renderBody()}/>
        );
    }
}

export default FamiliesContainer;