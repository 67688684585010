const initState = {
    activeMenu: ''
}

const Menu = (state = initState, action) => {
    if(action.type === 'SET_ACTIVE_MENU'){
        return{ ...state, activeMenu: action.menu }
    }else{
        return state;
    }
}

export default Menu;
