import React, {Component} from 'react';
import AppFrame from "../components/AppFrame";
import acerca from "../img/acerca.jpg";
import despensa from "../img/despensa.jpg";
import entrega from "../img/entrega.jpg";
import inicio from "../img/inicio.mp4";

class HomeContainer extends Component {
    adoptFamily = () => {
        this.props.history.push('/el-amor-se-contagia');
    }
    
    renderBody() {
        return (
            <div className="home-container">
                <div className="slider">
                    <video autoPlay muted loop playsInline>
                        <source src={inicio} type="video/mp4" />
                    </video>
                </div>
                <div className="section">
                    <div className="content">
                        <div className="column">
                            <p>Es una red creada para contagiar el amor vinculando familias en situación de pobreza que tienen algún niño con una enfermedad crónica (parálisis cerebral, discapacidad, leucemia, autismo, insuficiencia renal, esclerosis, etc) y familias que quieran ayudar comprometiéndose a adoptar uno o mas de estos niños a través de nuestro portal de adopciones logrando de esta forma cubrir el costo de la canasta básica por medio de una macrodespensa que se entregara de manera mensual a las familias adoptadas logrando así quitarles un peso de encima, mismo que pueden destinar a los cuidados del niño.</p>
                            <button className="button" onClick={() => this.adoptFamily()}>Adoptar una Familia</button>
                        </div>
                        <div className="column">
                            <img src={acerca} alt="El amor se contagia" />
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title_section">
                        <h2>¿Qué contiene esta macro despensa?</h2>
                    </div>
                    <div className="content">
                        <div className="column">
                            <img src={despensa} alt="El amor se contagia" />
                            <h3>Costo Total: $ 750.00 MXN</h3>
                        </div>
                        <div className="column">
                           <ul>
                              <li>2 kilos de frijol</li>
                              <li>2 kilos de arroz</li>
                              <li>1 kilo de lentejas</li>
                              <li>1 kilo de azúcar</li>
                              <li>1 kilo de sal</li>
                              <li>1 litro de aceite</li>
                              <li>12 litros de leche Tetrapack</li>
                              <li>8 latas de atún</li>
                              <li>7 bolsas de pasta</li>
                              <li>1 litro de puré de tomate</li>
                              <li>1 lata de jalapeños mediana</li>
                              <li>1 mayonesa</li>
                              <li>1 paquete grande de cereal</li>
                              <li>3 paquetes de galletas María</li>
                              <li>2 rollos de papel higiénico</li>
                              <li>2 jabones de barra</li>
                           </ul>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title_section">
                        <h2>¿Cómo puedo ayudar?</h2>
                    </div>
                    <div className="content">
                        <div className="column">
                            <p>Realizando vía depósito o transferencia la cantidad mensual de la macro despensa a la siguiente tarjeta de crédito que corresponde a las personas que nos estarán surtiendo el producto.</p>
                            <p className="payment">Banorte</p>
                            <p className="payment">4931 5800 0294 7312</p>
                            <p className="payment">Juan Alba Salas</p>
                            <p>Puedes hacer depósitos en el banco, Farmacias Guadalajara, Farmacias del Ahorro, Extra y Circle K.</p>
                            <p>Envíanos un mensaje vía Whatsapp al número <strong>(449) 920 0383</strong> con la foto de tu depósito o transferencia.</p>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title_section">
                        <h2>¿Cómo se que si se entregan las macro despensas?</h2>
                    </div>
                    <div className="content">
                        <div className="column">
                            <p>Al momento de realizar la entrega de la macro despensa a la familia que decidió apoyar, se le estará enviando a su celular una foto o vídeo con la entrega y el agradecimiento de la familia que está ayudando.</p>
                            <h3>GRACIAS POR CONTAGIAR EL AMOR</h3>
                            <p><i>"En verdad os digo que en cuanto lo hiciste a uno de estos hermanos míos, aún a los más pequeños a mi me lo hiciste"</i></p>
                        </div>
                        <div className="column">
                            <img src={entrega} alt="El amor se contagia" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <AppFrame body={this.renderBody()}/>
        );
    }
}

export default HomeContainer;
