import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import {validate} from './validate';

class NewSponsor extends Component {
    renderField = ({input, meta, type, label, name}) => (
        <div className="form-group">
            <label className="control-label" htmlFor={name}>{label}</label>
            <input className="form-control" {...input} type={!type ? 'text' : type} />
            { meta.touched && meta.error && <span>{meta.error}</span> }
        </div>
    )

    render() {
        const {handleSubmit, submitting, pristine} = this.props;
        return (
            <div className="form-sponsor-container">
                <form onSubmit={handleSubmit}>
                    <Field name="telefono" label="Celular (Whatsapp)" component={this.renderField} type="text" />
                    <button type="submit" disabled={pristine || submitting}>Crear Cuenta</button>
                </form>
            </div>
        );
    }
}

const NewSponsorForm = reduxForm({
    form: 'NewSponsorForm',
    validate
})(NewSponsor);

export default NewSponsorForm;