import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyA-dnF8v4A_dJnB_QVK5Dl8lVLmVNJMWDo",
    authDomain: "el-amor-se-contagia.firebaseapp.com",
    databaseURL: "https://el-amor-se-contagia.firebaseio.com",
    projectId: "el-amor-se-contagia",
    storageBucket: "el-amor-se-contagia.appspot.com",
    messagingSenderId: "246860157474",
    appId: "1:246860157474:web:6c61059b0c9ec9b902707b",
    measurementId: "G-HK3W1PR6YD"
};

firebase.initializeApp(config);
firebase.auth().signInWithEmailAndPassword('admin@elamorsecontagia.org','despensa2021sponsor').then( () => {});
export default firebase;
